  import React from 'react'
  import Layout from '../../../../components/App/Layout'
  import Navbar from '../../../../components/App/NavbarRV'
  import Footer from '../../../../components/App/FooterRV'
  import KDPImagePage from '../../../../assets/images/pages/2-2-2-0.jpg'
  
  const Details = () => {
      return (
          <Layout>
              <Navbar />
              <div>
                  <div className='container'>
                      <div className='page-title-content'>
                          <ul>
<li><a href='/'>Accueil</a></li><li><a href='/expertise-assure/'>Expertise d'assuré</a></li><li><a href='/expertise-assure/expert-assure/'>L'expert d'assuré</a></li><li>Objectifs</li>
                          </ul>
                      </div>
                  </div>
              </div>
              <section className="services-details-area ptb-100">
                  <div className="container">
                      <div className="row">
                          <div className="col-lg-8 col-md-12">
                              <div className="services-details-desc">
                                
<h1>Les Objectifs de l’Expert d’assuré</h1>

<p>La mission d’un expert d’assuré consiste à défendre les intérêts d’un assuré sinistré face à l’assurance.</p>
<div className='services-details-image'>
  <img src={KDPImagePage} alt="Objectifs" />
</div>


<p>Il examine également de façon minutieuse les contours de votre contrat d’assurance, vos conditions générales et les conditions particulières qui s’appliquent à votre contrat.</p>

<p>Les objectifs de l’expertise d’assuré sont multiples et comprennent : </p>

<p><u>Une assistance technique :</u> L’expert d’assuré intervient sur site afin de réaliser une visite technique lors de laquelle il va répertorier les désordres ou malfaçons, qu’il va compiler dans son rapport. Le rapport de l’expert d’assuré comprend différentes analyses : détermination des causes et origines des désordres, préconisations et chiffrage estimatif des travaux.</p>

<p><u>Un rôle de médiation :</u> L’expert d’assuré a pour objectif d’assurer un rôle de médiation avec l’assurance ou l’entreprise avec laquelle vous êtes en litige. </p>
<p>Dans un premier temps, il met tout en œuvre pour trouver des accords à l’amiable, afin de défendre vos droits et vos intérêts.</p>

<p>Si aucun accord n’est trouvé au terme des opérations de contre-expertise, l’assuré à un ultime recours : l’expertise judiciaire.</p>

<p><u>Une assistance et des conseils sur vos droits :</u> L’expert d’assuré vous accompagne également sur le plan juridique. Il maîtrise les principaux éléments du Droit de la Construction, Droit des assurances, afin de vous apporter des conseils juridiques en complément de ceux de votre Avocat.</p>

<p>En cas de litige, les Experts du cabinet RV Expertises interviennent dans un rôle de conciliateur, en médiation amiable afin de défendre vos intérêts jusqu’à indemnisation de votre sinistre à sa juste valeur.  </p>


<p> </p>

<div className="others-option d-flex align-items-center">
    <div className="option-item">
        <a class="default-btn" href="/contact/"><i className="flaticon-right"></i> Contacter un Expert du cabinet RV Expertises</a>
    </div>
</div>



                                
                              </div>
                          </div>
                          <div className="col-lg-4 col-md-12">
                              <div className="services-details-info">
                                  <ul className="services-list">
                                      
<li><a href='/expertise-assure/expert-assure/objectifs/' className='active'>Objectifs</a></li>
                                      
                                  </ul>
                                  <a href='javascript:history.go(-1)'>Retour</a>
                              </div>
                          </div>
                      </div>
                  </div>
              </section>
              <Footer />
          </Layout>
      );
  }

  export default Details